@use "../motif";
@use "../commons";

.Breadcrumbs {
  padding: 0.5rem 1rem;
  font-size: 18px;

  .path-part {
    color: motif.$textcolor;
    text-decoration: none;
    @include commons.text-collapse-ellipsis;
  }

  .separator {
    margin: 0 0.3em;
  }
}