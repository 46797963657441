.FileEntryDropArea {
  &.can-drop .can-drop-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-color: rgba(0,127,0,0.5);
  }
}
