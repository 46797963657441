@use "../motif";

.context-menu-vertical-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  pointer-events: none;
  padding-bottom: 25px; // to prevent context menus from being hidden behind scroll bars

  .context-menu-vertical-spacer {
    flex-grow: 0;
    flex-shrink: 1;
  }

  .context-menu-horizontal-wrapper {
    display: flex;
    flex-direction: row;
    padding-right: 25px; // to prevent context menus from being hidden behind scroll bars

    .context-menu-horizontal-spacer {
      flex-grow: 0;
      flex-shrink: 1;
    }

    .ContextMenu {
      pointer-events: auto;
    }
  }
}

.ContextMenu {
  outline: none;
  position: relative;

  .ContextSubMenu {
    background-color: motif.$bgcolor-highlighted;
    border: 1px solid darkgrey;

    &:not(.is-root) {
      position: absolute;
      top: -1px; // -1px because of border
      left: 100%;
      &.no-direction { visibility: hidden; }
      &.right { left: 100%; }
      &.left { left: auto; right: 100%; }
    }

    .group {
      .ContextMenuEntry {
        $submenu-icon-padding: 12px;

        position: relative;
        padding: 2px $submenu-icon-padding 2px 27px;
        min-width: 100px;

        display: flex;
        align-items: center;

        user-select: none;
        white-space: nowrap;

        &.highlighted {
          background-color: grey;
        }

        .entry-icon {
          position: absolute;
          left: 7px;
          width: 15px;
          height: 15px;
          display: flex;
          align-items: center;
          justify-content: center;

          &.disabled > svg {
            color: motif.$textcolor-disabled;
          }
        }

        .entry-text {
          margin-right: 30px;
          max-width: 400px;
          overflow: hidden;
          text-overflow: ellipsis;

          &.disabled {
            color: motif.$textcolor-disabled;
          }
        }

        .shortcut {
          color: grey;
          font-size: 12px;
          margin-left: auto;
        }

        .SubMenuIcon {
          position: absolute;
          right: calc(#{$submenu-icon-padding} / 2);
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid darkgrey;
      }
    }
  }
}
