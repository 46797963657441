@use "../motif";

.MainPage {
  display: grid;
  height: 100vh;
  grid-template-rows: 3em 1fr;

  .Breadcrumbs {
    grid-row-start: 1;
    grid-column: 1 / span 3;
    border-bottom: 1px solid motif.$textcolor;
    display: flex;
    align-items: center;
  }

  .FileBrowser {
    grid-row-start: 2;
    grid-column: 1;
    min-width: 0;
    min-height: 0;
    overflow: auto;
  }

  .file-browser-resizer {
    position: relative;
    border-right: 1px solid motif.$textcolor;

    .file-browser-resize-handle {
      position: absolute;
      width: 5px;
      height: 100%;
      left: 50%;
      transform: translateX(-50%);
      cursor: ew-resize;
    }
  }

  .FileViewer, .DirectoryViewer {
    grid-row-start: 2;
    grid-column: 3;
    min-width: 0;
  }
}

@media only screen and (max-width: 800px) {
  .MainPage {
    .FileBrowser, .file-browser-resizer {
      display: none;
    }
    .FileViewer, .DirectoryViewer {
      grid-column: 1 / span 3;
    }
  }
}
