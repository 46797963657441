@use "../motif";
@use "../commons";

.FileViewer {
  position: relative;
  min-width: 100%;
  min-height: 100%;
}

.ImageView {
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.TextView {
  position: relative;
  height: 100%;
  overflow-y: hidden;

  textarea {
    width: 100%;
    min-height: 100%;
    border: none;
    outline: none;
    resize: none;
    padding: 0.5em 0.5em 10em;
    background-color: motif.$bgcolor;
    color: motif.$textcolor;
  }

  .save-panel {
    position: absolute;
    display: inline-block;
    bottom: -1px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.3em 1em;
    background-color: motif.$bgcolor-highlighted;
    border: 1px solid motif.$textcolor;
    cursor: pointer;
  }

  .binary-overlay {
    padding: 1em 2em;
    background-color: motif.$bgcolor-highlighted;
    border: 1px solid motif.$textcolor;
    text-align: center;

    .button {
      margin-top: 0.5em;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
