@use "../motif";
@use "../commons";

$iconwidth: 16px;

.FileBrowser {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .root-drop-area {
    flex: 1 1;
    outline: none;
  }
}

.FileEntry {
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  padding: 0 8px;
  outline: none;

  &:hover, &.active {
    background-color: motif.$bgcolor-highlighted;
  }

  &.selected {
    background-color: motif.$textcolor;
    color: motif.$bgcolor;
  }

  .hierarchy-lines {
    position: relative;
    width: $iconwidth;
    height: 24px;
    margin-left: 6px;
    flex: 0 0 $iconwidth;

    .line {
      width: 1px;
      height: 100%;
      border-left: 1px solid grey;

      &.half-height {
        height: 50%;
      }
    }

    .tick {
      position: absolute;
      width: 50%;
      height: 2px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      border-bottom: 1px solid grey;
    }
  }

  .FileEntryDropArea {
    width: 100%;
    min-width: 0;
    display: flex;
    align-items: center;

    .icon {
      display: inline-flex;
      width: $iconwidth;
      height: 16px;
      justify-content: center;
      align-items: center;
      flex: 0 0 $iconwidth;

      &.folder {
        color: motif.$folder-color;
      }
    }

    .label {
      margin-left: 8px;
      min-width: 0;
      @include commons.text-collapse-ellipsis;
    }
  }
}