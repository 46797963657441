@use "motif";

:root {
  color-scheme: dark;
}

html, body {
  margin: 0;
  font-family: motif.$textfont;
  color: motif.$textcolor;
  background-color: motif.$bgcolor;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}
