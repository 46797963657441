@use "../motif";

.ProgressDialog {
    position: fixed;
    min-width: 300px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    padding: 0.6em;
    background-color: motif.$bgcolor-highlighted;
    border: 1px solid lightgrey;
    z-index: 1000;

    .label {
        display: flex;
        margin-bottom: 0.6em;

        .status {flex-grow: 1}
        .time-ellapsed {float: right}
    }
}