@use "../motif";
@use "../commons";

.DirectoryViewer {
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 100%;
  overflow-y: auto;

  table {
    table-layout: fixed;
    width: 100%;

    tbody > tr:hover {
      cursor: pointer;
      background-color: motif.$bgcolor-highlighted;
    }

    thead {
      position: sticky;
      top: 0;
      background-color: motif.$bgcolor;
      z-index: 1;

      th {
        position: relative;
        text-align: left;
        font-weight: bold;
        border-bottom: 1px solid motif.$textcolor;

        .SortIndicator {
          margin-left: 4px;
        }
      }
    }

    td {
      @include commons.text-collapse-ellipsis;
      border-collapse: collapse;
    }

    th, td {
      @include commons.text-collapse-ellipsis;
      user-select: none;
      &:first-child { padding-left: 1em }
      &:last-child { padding-right: 1em }
    }

    .file-row {
      position: relative;
      outline: none;

      &.selected {
        background-color: motif.$textcolor;
        color: motif.$bgcolor;
      }

      .name-column {
        display: flex;
        align-items: center;

        .icon {
          display: inline-flex;
          width: 16px;
          height: 16px;
          justify-content: center;
          flex-shrink: 0;

          &.folder {
            color: motif.$folder-color;
          }
        }

        .label {
          margin-left: 5px;
          @include commons.text-collapse-ellipsis;
        }
      }
    }
  }

  .file-drop-zone {
    flex: 1 1;
    outline: none;

    .drop-label {
      background-color: motif.$bgcolor-highlighted;
      border: 1px solid motif.$textcolor;
      padding: 1em 2em;
    }
  }
}

.SortIndicator {
  width: 12px;
  height: 12px;
  padding-bottom: 1px;
}