.Overlay {
  top: 0;
  left: 0;

  &:not(.fullscreen) {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &.fullscreen {
    position: fixed;
    width: 100vw;
    height: 100vh;
  }

  &.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:not(.non-blocking) {
    background-color: rgba(0,0,0,0.3);
  }

  &.non-blocking {
    pointer-events: none;
  }
}